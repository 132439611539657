import PauseIcon from '@/assets/icons/pause.svg?react';
import PlayIcon from '@/assets/icons/play.svg?react';
import { useEffect, useRef, useState } from 'react';
import { useIsClient, useWindowSize } from 'usehooks-ts';
import WaveSurfer from 'wavesurfer.js';

import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { Skeleton } from '@/components/ui/skeleton';
import { cn } from '@/lib/utils';
import { logger } from '@listening/shared';

export function VoiceSample({
  imgSrc,
  label,
  audioSrc,
  className,
  classNameBtn,
  classNameAvatar,
  voiceWidth = 180,
}: {
  imgSrc: string;
  label: string;
  audioSrc: string;
  className?: string;
  classNameBtn?: string;
  classNameAvatar?: string;
  voiceWidth?: number;
}) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const waveSurferRef = useRef<WaveSurfer | null>(null);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const [isPlaying, setIsPlaying] = useState(false);
  const [isWaveSurferReady, setIsWaveSurferReady] = useState(false);

  const { width = 0 } = useWindowSize();
  const isMobile = width < 1360;

  useEffect(() => {
    if (!containerRef.current) return;
    const waveSurfer = WaveSurfer.create({
      container: containerRef.current,
      height: 24,
      width: isMobile ? 180 : voiceWidth,
      url: audioSrc,
      progressColor: '#7CD4FD',
      barGap: 3,
      barWidth: 3,
      barRadius: 10,
      barHeight: 0.9,
      normalize: true,
      waveColor: '#D9D9D9',
      cursorColor: 'transparent',
      interact: false,
    });
    waveSurfer.on('ready', () => {
      setIsWaveSurferReady(true);
      logger.log('WaveSurfer is ready');
      waveSurferRef.current = waveSurfer;
    });

    waveSurfer.on('finish', () => {
      setIsPlaying(false);
    });

    return () => {
      waveSurfer.destroy();
    };
  }, [audioSrc, isMobile, voiceWidth]);

  const isClient = useIsClient();
  const isLoading = !isClient || !isWaveSurferReady;

  return (
    <div
      className={cn(
        'relative rounded-full bg-white shadow-[0px_4.303px_36.578px_0px_rgba(193,216,251,0.50)]',
        isMobile ? '' : className,
      )}
    >
      {isLoading && <Skeleton className="absolute inset-0 rounded-full" />}
      <div
        className={cn(
          'flex size-full items-center gap-2 p-3',
          isLoading && 'invisible',
        )}
      >
        <Avatar className={classNameAvatar}>
          <AvatarImage src={imgSrc} />
          <AvatarFallback>
            <Skeleton className="size-10 rounded-full" />
          </AvatarFallback>
        </Avatar>
        <div className="flex grow flex-col">
          <h3 className="text-lg font-[500]">{label}</h3>
          <div ref={containerRef} className="h-6 w-[180px]" />
        </div>
        <Button
          className={cn(
            'h-10 w-10 rounded-full p-1',
            classNameBtn,
            !isPlaying
              ? '!border !border-solid border-primary bg-background text-primary hover:bg-secondary/80'
              : 'js-voice-play',
          )}
          onClick={(event) => {
            if (!isButtonClicked) {
              setIsButtonClicked(true); // Set the flag to true when click begins

              document.querySelectorAll('.js-voice-play').forEach((element) => {
                if (
                  element instanceof HTMLElement &&
                  element !== event.currentTarget
                ) {
                  element.click();
                }
              });

              void waveSurferRef.current?.playPause();
              setIsPlaying(waveSurferRef.current?.isPlaying() ?? false);

              setIsButtonClicked(false); // Reset the flag when actions are done
            }
          }}
        >
          {isPlaying ? (
            <PauseIcon className="size-4 text-white" fill="white" />
          ) : (
            <PlayIcon className="size-4" />
          )}
        </Button>
      </div>
    </div>
  );
}
